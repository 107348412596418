<template>
  <div>
    <b-row>
      <b-col v-for="(item, index) in rows" lg="3" sm="6" :key="index">
        <card-home-catalogs :title="item.title" :verified="item.verified" :unverified="item.unverified" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {  BRow, BCol } from 'bootstrap-vue'
import CardHomeCatalogs from "@/components/CardHomeCatalogs";
import DashboardService from "@/services/DashboardService";
export default {
  components: {
    CardHomeCatalogs,
    BRow,
    BCol,
  },
  data(){
    return {
      rows : []
    }
  },
  created() {
    this.getData()
  },
  methods:{
    getData(){
      DashboardService.get().then(response => {
        this.rows = response.data.data.values;
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style>

</style>
