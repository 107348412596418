<template>

      <b-card no-body>
        <b-card-header class="p-1 justify-content-center">
          <h4 v-text="title" />
        </b-card-header>

        <b-row class="text-center mx-0">
          <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
            <b-card-text class="text-success mb-0">
              Aprobados
            </b-card-text>
            <h3 class="text-muted mb-50">
              {{ verified }}
            </h3>
          </b-col>

          <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
            <b-card-text class="text-warning mb-0">
              No Aprobados
            </b-card-text>
            <h3 class="text-muted mb-0">
              {{ unverified }}
            </h3>
          </b-col>
        </b-row>
      </b-card>

</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol, BCardHeader } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardHorizontal,
    BCardHeader
  },
  props: {
    title: {
      default(){
        return ''
      }
    },
    verified : {},
    unverified: {}
  }
}
</script>

<style scoped>

</style>
